
.common-center-dialog {
  &.fullscreen {
    z-index: 100;
    &.main {
      z-index: 10000;
    }
  }
  .q-dialog__backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
  .dialog-card {
    border-radius: 1.25rem;
    padding: 2.125rem 1.25rem 1.25rem;
    &.fixed-width {
      min-width: 23.75rem;
    }
    &.relative-width {
      min-width: calc(100vw - 2.5rem);
    }
  }
  .dialog-card-section {
    padding: 0 0 2.8125rem;
  }
  .main-error-icon {
    margin-bottom: 0.75rem;
  }
  .dialog-title {
    padding: 0 0 1.25rem;
  }
  .main-error-comment {
    margin-bottom: -1.8175rem;
    padding: 1.25rem 0.625rem;
    border-radius: 1.25rem;
    background: #f4f4f4;

    > .text-l-b-3 {
      letter-spacing: -0.045rem;
    }
    > .text-2xs-3 {
      letter-spacing: -0.03375rem;
    }
  }

  .center-dialog-button {
    color: white;
    border-radius: 0.625rem;
    background: linear-gradient(180deg, #008aff 26.56%, #0069c2 100%);
    box-shadow: 0px 13px 9px 0px rgba(0, 0, 0, 0.2);
    -webkit-appearance: none;
    padding: 0.8125rem 1.35rem 0.75rem 1.35rem;
  }
  .dialog-button {
    border-radius: 0.625rem;
    padding: 0.8125rem 0.75rem 0.75rem;
    &.first-button {
      color: #666666;
    }
    &.second-button {
      color: white;
      background: linear-gradient(180deg, #0172f0 26.56%, #0057b9 100%);
      -webkit-appearance: none;
      margin-left: 0.46875rem;
    }
  }
  .dialog-space {
    width: 0.94rem;
    flex-grow: unset !important;
  }
}
