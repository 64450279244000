.turn,
.blink,
.shake,
.fade {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.turn {
  animation-name: turn;
}
.blink {
  animation-name: blink;
}
.shake {
  animation-name: shake;
}
.fade {
  animation-name: fade;
}

@keyframes turn {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-6px);
  }
  75% {
    transform: translateX(6px);
  }
}
@keyframes fade {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
}
@keyframes colorChange {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
