.text-4xl-b {
  font-size: 2.5rem !important;
  line-height: 3.75rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-3xl-b {
  font-size: 2rem !important;
  line-height: 3rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-3xl {
  font-size: 2rem !important;
  line-height: 2.8125rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-2xl-b {
  font-size: 1.75rem !important;
  line-height: 2.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-2xl-3 {
  font-size: 1.75rem !important;
  line-height: 2.625rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;
}

.text-xl-b-3 {
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;
}

.text-xl-b {
  font-size: 1.625rem !important;
  line-height: 2.4375rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-l-b-3 {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;
}

.text-l-b {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-l-3 {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;
}

.text-l {
  font-size: 1.5rem !important;
  line-height: 2.25rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-m-b {
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-m {
  font-size: 1.375rem !important;
  line-height: 2.0625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-s-b {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-s-3 {
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;
}

.text-s {
  font-size: 1.25rem !important;
  line-height: 1.875rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-xs-b {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-xs-b-3 {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3% !important;
  font-weight: 700 !important;
}

.text-xs {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-xs-3 {
  font-size: 1.125rem !important;
  line-height: 1.6875rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;
}

.text-2xs-b {
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-2xs-3 {
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: -3% !important;
  font-weight: 400 !important;
}

.text-2xs {
  font-size: 1rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-3xs-b {
  font-size: 0.875rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 700 !important;
}

.text-3xs {
  font-size: 0.875rem !important;
  line-height: 1.625rem !important;
  letter-spacing: 0 !important;
  font-weight: 400 !important;
}

.text-tertiary {
  color: #e8f0f8;
}

.text-quaternary {
  color: #999999;
}

.text-quinary {
  color: #ececec;
}

.text-senary {
  color: #666666;
}

.text-excellent {
  color: #1a96ff;
}

.text-great {
  color: #10b972;
}

.text-good {
  color: #ffc824;
}

.text-normal {
  color: #f24040;
}

.text-Memory {
  color: #10b972;
}

.text-ExecutiveFunction {
  color: #a660dc;
}

.text-Calculation {
  color: #1996ff;
}

.text-Language {
  color: #10afb9;
}

.text-Attention {
  color: #ff7a00;
}

.text-VisuoSpatial {
  color: #ff6bcd;
}

.text-a-primary {
  color: #2c4367;
}

.text-a-secondary {
  color: #6e859d;
}

.text-a-tertiary {
  color: #799cab;
}

.text-a-quaternary {
  color: #0089ff;
}

.bg-tertiary {
  background-color: #e8f0f8;
}

.bg-quaternary {
  background-color: #999999;
}

.bg-quinary {
  background-color: #ececec;
}

.bg-senary {
  background-color: #666666;
}

.bg-excellent {
  background-color: #1a96ff;
}

.bg-great {
  background-color: #10b972;
}

.bg-good {
  background-color: #ffc824;
}

.bg-normal {
  background-color: #f24040;
}

.bg-Memory {
  background-color: rgba(16, 185, 114, 0.1);
}

.bg-ExecutiveFunction {
  background-color: rgba(166, 96, 220, 0.1);
}

.bg-Calculation {
  background-color: rgba(25, 150, 255, 0.1);
}

.bg-Language {
  background-color: rgba(16, 175, 185, 0.1);
}

.bg-Attention {
  background-color: rgba(255, 122, 0, 0.1);
}

.bg-VisuoSpatial {
  background-color: rgba(255, 107, 205, 0.1);
}

.bg-a-primary {
  background-color: #2c4367;
}

.bg-a-secondary {
  background-color: #6e859d;
}

.bg-a-tertiary {
  background-color: #799cab;
}

.bg-a-quaternary {
  background-color: #0089ff;
}

.turn,
.blink,
.shake,
.fade {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.turn {
  animation-name: turn;
}

.blink {
  animation-name: blink;
}

.shake {
  animation-name: shake;
}

.fade {
  animation-name: fade;
}

@keyframes turn {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-6px);
  }
  75% {
    transform: translateX(6px);
  }
}
@keyframes fade {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
}
@keyframes colorChange {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

#q-app,
.q-dialog {
  white-space: pre-line !important;
}
#q-app .fixed-width,
.q-dialog .fixed-width {
  width: 28.75rem;
}
#q-app .default-view-layout,
.q-dialog .default-view-layout {
  padding: 1.5rem 1.25rem 2.5rem;
}
#q-app .responsive-full-height,
.q-dialog .responsive-full-height {
  min-height: calc(var(--vh, 1vh) * 100);
}
#q-app .responsive-at-height,
.q-dialog .responsive-at-height {
  min-height: calc(var(--vh, 1vh) * 100 - 6.75rem);
}
#q-app .chat-content-bubble,
.q-dialog .chat-content-bubble {
  margin-bottom: 0.5rem !important;
}
#q-app .chat-text-box,
.q-dialog .chat-text-box {
  width: fit-content;
  padding: 0.625rem 1rem;
  border-radius: 1.25rem;
  box-shadow: 0 0 0 1px #ececec;
  outline: none;
  -webkit-appearance: none;
  background: #fff;
}