
.score-info-title {
  margin: 0;
  padding-bottom: 0.75rem;
}
.score-info-container {
  padding-bottom: 1.5rem;
}
.souvenir {
  padding: 0 0.875rem 0.75rem 0.875rem;
}
