@import 'font';
@import 'mixin';
@import 'color';
@import 'quasar.variables';
@import 'animation';

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

#q-app,
.q-dialog {
  white-space: pre-line !important;

  .fixed-width {
    // width: 460px
    width: 28.75rem;
  }
  .default-view-layout {
    // padding: 24px 20px 40px
    padding: 1.5rem 1.25rem 2.5rem;
  }
  .responsive-full-height {
    min-height: calc(var(--vh, 1vh) * 100);
  }
  .responsive-at-height {
    min-height: calc(var(--vh, 1vh) * 100 - 6.75rem);
  }

  .chat-content-bubble {
    margin-bottom: 0.5rem !important;
  }
  .chat-text-box {
    width: fit-content;
    padding: 0.625rem 1rem;
    border-radius: 1.25rem;
    box-shadow: 0 0 0 1px #ececec;
    outline: none;
    -webkit-appearance: none;
    background: #fff;
  }
}
